/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { accessTokenCache, endSession } from '../../services'
import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { RootState } from '../../store'
import { User, UserEligibility, UserProfileFormData } from '../../types'
import { getUser, getUncachedUser, updateUser } from './userService'

type UserStateType = {
  authenticated: boolean
  user: User | undefined
  verifications: UserEligibility[] | undefined
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  isUncachedUserSuccess: boolean
}

const initialState: UserStateType = {
  authenticated: false,
  user: undefined,
  verifications: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isUncachedUserSuccess: false,
}

// Get users
export const fetchUser = createAsyncThunk('user/get', async (_, thunkAPI) => {
  try {
    const getUserWithRetry = wrapWithRetryAsync(getUser)
    const userAndVerifications = await getUserWithRetry()
    return userAndVerifications
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get users
export const fetchUncachedUser = createAsyncThunk('user/getUncached', async (_, thunkAPI) => {
  try {
    const getUncachedUserWithRetry = wrapWithRetryAsync(getUncachedUser)
    const userAndVerifications = await getUncachedUserWithRetry()
    return userAndVerifications
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateUserProfile = createAsyncThunk('user/update', async (params: UserProfileFormData, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { user } = pre.userReducer
    const t = await accessTokenCache.get<string>()
    const newUser = t ? await updateUser(params) : undefined

    const newUserData = {
      ...user,
      user: newUser,
    }

    return newUserData
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('user/logout', async (_, thunkAPI) => {
  try {
    await endSession()
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.authenticated = true
        state.user = action.payload?.user
        state.verifications = action.payload?.verifications
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.isSuccess = false
        state.authenticated = false
        state.user = undefined
        state.verifications = []
        throw new Error(action.payload as string)
      })
      .addCase(fetchUncachedUser.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
        state.isUncachedUserSuccess = false
      })
      .addCase(fetchUncachedUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.authenticated = true
        state.user = action.payload?.user
        state.verifications = action.payload?.verifications
        state.isUncachedUserSuccess = true
      })
      .addCase(fetchUncachedUser.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.isSuccess = false
        state.authenticated = false
        state.user = undefined
        state.verifications = []
        state.isUncachedUserSuccess = false
        throw new Error(action.payload as string)
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload.user
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        throw new Error(action.payload as string)
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.authenticated = false
        state.user = undefined
        state.verifications = []
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        throw new Error(action.payload as string)
      })
  },
})

export const { reset } = userSlice.actions
export default userSlice.reducer
