import axios from 'axios'

import { API, SPA_URL, ERROR_PAGE_PATH } from '../constants'
import { Settings } from '../types'
import { uuid } from '../utils'
import { apiCacheInvalidationFlagCache, shoppingInstanceIdCache, appSettingsCache, accessTokenCache } from './cache'

const isIe = !!window.document.documentMode

const headers = isIe
  ? {
      Pragma: 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
  : {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }

export const api = axios.create({
  baseURL: API,
  headers,
})

export const cart = axios.create({
  baseURL: `${API}/cart`,
  headers,
})

export const cartItem = axios.create({
  baseURL: `${API}/cart/item`,
  headers,
})

export const getHeaders = async (disableCache = false) => {
  let shoppingInstanceId = await shoppingInstanceIdCache.get<string>()

  if (!shoppingInstanceId) {
    const newShoppingInstanceId = uuid()
    await shoppingInstanceIdCache.set(newShoppingInstanceId)
    shoppingInstanceId = newShoppingInstanceId
  }

  const cacheInvalid = (await apiCacheInvalidationFlagCache.get()) ?? disableCache ? 'no-cache' : null
  const appSettings = await appSettingsCache.get<Settings>()
  const accessToken = await accessTokenCache.get<string>()
  const locale = appSettings?.locale.language || 'en-us'
  const currency = appSettings?.currency.currencyAbbreviation || 'USD'
  const headerSet = {
    'Content-Type': 'application/json',
    'Format-Locale': locale,
    'Format-Currency': currency,
    ShoppingInstanceId: shoppingInstanceId,
  }
  const effectiveHeaderSet = cacheInvalid ? { ...headerSet, 'Cache-Control': `${cacheInvalid}` } : headerSet
  return accessToken ? { ...effectiveHeaderSet, Authorization: `Bearer ${accessToken}` } : effectiveHeaderSet
}

export const performRedirectToErrorPage = async () => {
  await apiCacheInvalidationFlagCache.clear()
  window.location.replace(SPA_URL + ERROR_PAGE_PATH)
  return
}

type wrapWithRetryAsyncType = <T, Params extends unknown[]>(
  func: (...args: Params) => Promise<T>,
  errorCallback?: () => Promise<void>,
  maxRetries?: number
) => (...args: Params) => Promise<T>

export const wrapWithRetryAsync: wrapWithRetryAsyncType =
  <T, Params extends unknown[]>(
    func: (...args: Params) => Promise<T>,
    errorCallback = async () => await performRedirectToErrorPage(),
    maxRetries = 1
  ) =>
  async (...args: Params) => {
    return await func(...args)
      .then(async (res) => {
        await apiCacheInvalidationFlagCache.clear()
        return await res
      })
      .catch(async (err) => {
        if (maxRetries <= 0) {
          await errorCallback()
          throw Error((err as Error).message)
        }
        await apiCacheInvalidationFlagCache.clear()
        return await wrapWithRetryAsync(func, errorCallback, maxRetries - 1)(...args)
      })
  }
